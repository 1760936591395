<template>
  <scroll-layout class="orderly-statistics-list">
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        v-if="topTipStatus"
        :description="topTip"
        @click="topTipStatus = false"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
  </scroll-layout>
</template>

<script>
  import { orderlyPage, barrierPage } from '@/service/apis';
  import { getLastTime, getSelectRang } from '@/utils';

  export default {
    name: 'orderlyStatisticsList',
    data() {
      return {
        showDrawer: false,
        controlId: '',
        topTipStatus: true,
        topTip: '温馨提示：由于统计数据量过大，最多可筛选7日数据展示。',
        searchParams: {
          hisDate: [getLastTime('yyyy-MM-dd', 1), getLastTime('yyyy-MM-dd', 1)],
          startTime: getLastTime('yyyy-MM-dd', 1),
          endTime: getLastTime('yyyy-MM-dd', 1),
          stationId: '',
        },
        stationIdData: [],
        tableTitle: '明细列表',
        tableOperateButtons: [], // 列表操作
        tableData: [],
        tableColumns: [
          { label: '时间', prop: 'hisDate', minWidth: '150px' },
          { label: '电站名称', prop: 'stationName', minWidth: '150px' },
          { label: '电站总功率(KW)', prop: 'totalRatedPower', minWidth: '150px' },
          { label: '电站允许总功率(KW)', prop: 'allowRatedPower', minWidth: '150px' },
          { label: '电桩实时总功率(KW)', prop: 'realTimePower', minWidth: '150px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            label: '时间维度',
            key: 'hisDate',
            comName: 'YkcDatePicker',
            placeholder: '请选择时间维度',
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationIdData,
          },
        ];
      },
    },
    created() {
      this.requestStationData();
      // this.requestList();
    },
    methods: {
      /**
       * 获取电站数据
       */
      requestStationData() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取归属电站数据+++++', res);
            this.stationIdData = res.map(item => ({
              id: item.stationId,
              name: item.stationName,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        if (!this.searchParams.stationId) {
          this.$message.warning('请选择电站');
          return;
        }
        if (this.searchParams.hisDate && this.searchParams.hisDate.length === 2) {
          if (!getSelectRang(this.searchParams.hisDate, 7)) {
            this.$message.warning('时间维度不能超过7天！');
            return;
          }
        }
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        orderlyPage
          .orderlyControlStatistics(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        const startTime = getLastTime('yyyy-MM-dd', 1);
        const endTime = getLastTime('yyyy-MM-dd', 1);
        this.searchParams.hisDate = [startTime, endTime];
        this.searchParams.startTime = startTime;
        this.searchParams.endTime = endTime;
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        // 时间选择
        if (
          searchData.hisDate &&
          Array.isArray(searchData.hisDate) &&
          searchData.hisDate.length > 0
        ) {
          const [startTime, endTime] = searchData.hisDate;
          this.searchParams.startTime = startTime;
          this.searchParams.endTime = endTime;
        }
        this.requestList();
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
    },
  };
</script>
